import React from "react"
import { graphql, navigate } from "gatsby"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { format, parseISO } from "date-fns"
import { pt } from "date-fns/locale"

export const query = graphql`
  query Article($id: ID!) {
    api {
      article(id: $id) {
        id
        slug
        title
        created_at
        content
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 2.5
              )
            }
          }
        }
      }
    }
  }
`

const ArticlePage = ({ data, pageContext, location }) => {
  const article = data.api.article
  const created_at = parseISO(article.created_at)

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Article",
    name: article.title,
    description: article.content.replace(/<\/?[^>]+(>|$)/g, ""),
    image: {
      "@type": "ImageObject",
      url: article.image.url || "",
    },
  }

  return (
    <Layout>
      <Seo title={`${article.title}`} postStructuredData={structuredData} />
      <Container className="article single-post half-padding-top padding-bottom">
        {article.image && (
          <Row>
            <Col>
              <GatsbyImage
                image={article.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </Col>
          </Row>
        )}
        <Row className="bg-grey no-gutters pb-3">
          <Col md={12}>
            <div className="pt-3 px-4">
              <div className="d-flex align-items-center article-title">
                <div>
                  <span>
                    {format(created_at, "dd 'de' MMMM 'de' yyyy", {
                      locale: pt,
                      timeZone: "America/Sao_Paulo",
                    })}
                  </span>
                  <h2>{article.title}</h2>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="py-3 px-4">
              <div
                dangerouslySetInnerHTML={{ __html: article.content }}
                className="post-content"
              />
            </div>
            <div className="text-center">
              <Button onClick={() => navigate("/blog")}>Voltar</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ArticlePage
